<template>
  <!-- Wrapper -->
  <div
    class="vlw__cti-question"
    :class="{ 'vlw__cti-question--loading': question.loading }"
  >
    <Collapse
      :initiallyCollapsed="!question.expanded"
      :label="questionTitle"
      @toggle="isCollapsed => (question.expanded = !isCollapsed)"
    >
      <template slot="buttons">
        <div class="flex items-center flex-grow justify-between">
          <div class="flex items-center">
            <div class="vlw__cti-question__status">
              <span
                class="vl-pill vl-pill--error"
                v-if="question.priorityCode === 'High'"
                >Prio hoog</span
              >
              <span class="vl-pill" v-if="question.isInProgress"
                >In behandeling</span
              >
              <span
                class="vl-pill vl-pill--success"
                v-if="!question.isInProgress"
                >Afgehandeld</span
              >
              <span class="vl-pill vl-pill--warning" v-if="isReadOnly"
                >Alleen lezen</span
              >
            </div>
          </div>

          <div>
            <VlLoader v-if="question.loading"></VlLoader>

            <VlLink
              v-if="!question.loading && hasAccess"
              class="flex items-center"
              href="#"
              @click.prevent="$emit('delete')"
            >
              <VlIcon class="mr-4" icon="trash" />Vraag verwijderen
            </VlLink>
          </div>
        </div>
      </template>

      <div>
        <!-- Error messages -->
        <div v-if="!question.isValid" role="alert" class="vl-alert">
          <div class="vl-alert__content">
            <div
              class="vl-alert__message"
              v-html="question.errors.join('<br>')"
            ></div>
          </div>
        </div>

        <!-- Details -->
        <div class="vlw__cti__details">
          <div class="vlw__cti-question__details__item">
            <div class="vlw__cti__details-item__label">Status</div>
            <div class="vlw__cti__details-item__value">
              <select
                class="vl-select"
                :value="statusId"
                :disabled="isReadOnly"
                @change="handleOnChangeStatus"
              >
                <option disabled="disabled">Status</option>
                <option
                  v-for="s in questionStatuses"
                  :key="s.id"
                  :value="s.id"
                  :disabled="isStatusDisabled(s)"
                  v-text="s.name"
                ></option>
              </select>
            </div>
          </div>
          <div class="vlw__cti-question__details__item">
            <div class="vlw__cti__details-item__label">Substatus</div>
            <div class="vlw__cti__details-item__value">
              <select
                class="vl-select"
                v-model="question.subStatus"
                :disabled="isReadOnly"
              >
                <option disabled="disabled">Substatus</option>
                <option
                  v-for="s in subStatuses"
                  :key="s.id"
                  :value="s.id"
                  v-text="s.name"
                  :disabled="isSubStatusDisabled(s)"
                ></option>
              </select>
            </div>
          </div>
          <div class="vlw__cti-question__details__item">
            <div class="vlw__cti__details-item__label">Behandelthema</div>
            <div class="vlw__cti__details-item__value">
              <select
                class="vl-select"
                :disabled="isThemeDisabled"
                v-model="question.themeId"
              >
                <option disabled="disabled">Behandelthema</option>
                <option
                  v-for="t in themes"
                  :key="t.id"
                  :value="t.id"
                  v-text="t.name"
                ></option>
              </select>
            </div>
          </div>
          <div class="vlw__cti-question__details__item">
            <div class="vlw__cti__details-item__label">Antwoord via</div>
            <div class="vlw__cti__details-item__value">
              <select
                class="vl-select"
                v-model="question.responseTypeCode"
                :disabled="isReadOnly"
              >
                <option disabled="disabled">Kanaal</option>
                <option
                  v-for="r in responseTypeCodes"
                  :key="r.key"
                  :value="r.key"
                  v-text="r.name"
                ></option>
              </select>
            </div>
          </div>
          <div class="vlw__cti-question__details__item">
            <div class="vlw__cti__details-item__label">Ernst</div>
            <div class="vlw__cti__details-item__value">
              <select
                class="vl-select"
                v-model="question.priorityCode"
                :disabled="isReadOnly"
              >
                <option disabled="disabled">Ernst</option>
                <option
                  v-for="p in priorities"
                  :key="p.key"
                  :value="p.key"
                  v-text="p.name"
                ></option>
              </select>
            </div>
          </div>
          <div
            class="vlw__cti-question__details__item"
            v-if="showLanguageIfNotRequiredInEndRegistration"
          >
            <div class="vlw__cti__details-item__label">Taal</div>
            <div class="vlw__cti__details-item__value">
              <select
                class="vl-select"
                v-model="contact.completionInfo_Language"
                :disabled="isReadOnly"
              >
                <option disabled="disabled">Taal</option>
                <option
                  v-for="l in languages"
                  :key="l.key"
                  :value="l.key"
                  v-text="l.name"
                ></option>
              </select>
            </div>
          </div>
        </div>

        <!-- Question content -->
        <div class="vlw__cti-question__content">
          <!-- Left -->
          <div>
            <h3>Vraag</h3>
            <textarea
              class="vl-textarea vl-textarea--block"
              ref="title"
              v-model="question.title"
              :disabled="isReadOnly"
            ></textarea>
            <div class="vlw__cti-question__content--title">
              Extra informatie
            </div>
            <textarea
              class="vl-textarea vl-textarea--block"
              v-model="question.extraInformation"
              :disabled="isReadOnly"
            ></textarea>
            <div v-if="isKindEnGezin">
              <div class="vlw__cti-question__content--title">
                Opvangvoorziening
              </div>

              <VlInputField
                v-model="question.daycare"
                :disabled="isReadOnly"
                class="w-full"
              />
            </div>
          </div>

          <!-- Right -->
          <div>
            <!-- Citizen info -->
            <div class="vlw__cti-question__content__citizen">
              <div class="vl-u-display-flex">
                <h3>Gegevens burger</h3>
              </div>

              <!-- Selected citizen -->
              <CitizenDetails
                v-if="question.citizen.id"
                class="vlw__cti-question__content__citizen--details"
                :citizen="question.citizen"
                :disabled="!!question.escalationPoint"
                @open="onOpenCitizen"
                @unlink="unlinkCitizen"
              >
                <QuestionVariableFields
                  :question="question"
                  :disabled="isReadOnly"
                />
              </CitizenDetails>

              <MiragePersonDetails
                v-if="question.kindEnGezin_PersonId"
                :miragePersoonId="question.kindEnGezin_PersonId"
                @reset="unlinkChild"
                :disabled="!!question.escalationPoint"
              >
                <QuestionVariableFields
                  :question="question"
                  :disabled="isReadOnly"
                />
              </MiragePersonDetails>

              <form
                @submit.prevent="onSearchCitizen"
                v-if="!question.citizen.id && !question.kindEnGezin_PersonId"
              >
                <!-- First name -->
                <div>
                  <label class="vl-form__label">
                    <span>Voornaam</span>
                    <VlInputField
                      v-model="search.firstName"
                      :disabled="!hasAccess"
                    />
                  </label>

                  <!-- Last name -->
                  <label class="vl-form__label">
                    <span>Naam</span>
                    <VlInputField
                      v-model="search.lastName"
                      :disabled="!hasAccess"
                    />
                  </label>

                  <!-- Birthdate -->
                  <label
                    v-if="question.isBirthDateRequired"
                    class="vl-form__label"
                  >
                    <span>Geboortedatum</span>

                    <div class="vl-u-display-inline-block">
                      <Datepicker
                        v-model="search.birthdate"
                        :disabled="!hasAccess"
                      />
                    </div>
                  </label>
                </div>

                <!-- Variable fields -->
                <QuestionVariableFields
                  :question="question"
                  :disabled="isReadOnly"
                />

                <!-- Search button -->
                <div class="flex">
                  <VlButton
                    v-if="hasAccess"
                    type="submit"
                    icon="search"
                    :mod-icon-before="true"
                    :mod-narrow="true"
                    >Zoek een burger</VlButton
                  >

                  <VlButton
                    v-if="isKindEnGezin"
                    class="ml-4"
                    icon="search"
                    :mod-icon-before="true"
                    :mod-narrow="true"
                    @click.prevent="showSearchChildModal = true"
                    >Zoek een kind</VlButton
                  >
                </div>

                <!-- Hidden button to simulate form behaviour (enter to submit) -->
                <VlButton
                  v-show="false"
                  @click.prevent="showCitizenModal = true"
                ></VlButton>
              </form>

              <ExternalApplicationsButtons class="mt-4" :question="question" />
            </div>

            <!-- escaleren -->
            <div v-if="!question.escalationPoint">
              <div class="mb-4">
                <VlButton
                  v-if="hasRegularEscalationPoints"
                  @click.prevent="showEscalationPointsModal = true"
                  :mod-narrow="true"
                  :mod-secondary="true"
                  :mod-disabled="!canEscalate"
                  >Escaleren</VlButton
                >
              </div>

              <ManuallyEscalateButtons
                v-if="canManuallyEscalate"
                :disabled="!canEscalate"
                :question="question"
              />
            </div>

            <div v-else>
              <div class="uppercase font-bold">Geëscaleerd naar:</div>
              <div>
                {{
                  question.escalationPoint.title ||
                    question.escalationPoint.name
                }}
              </div>
            </div>
          </div>
          <div v-if="this.isScreenSharingAllowed && this.hasAccess">
            <Screenshots :question="question" />
          </div>
        </div>

        <!-- Answers -->
        <div class="vlw__cti-question__answers" v-if="hasAccess">
          <!-- Answer search -->
          <h3>Zoek een oplossing</h3>

          <!-- Search -->
          <VlSearch
            name="answerSearch"
            id="answerSearch"
            list="vlsKeywords"
            v-model="searchValue"
            :mod-inline="true"
            @submit="onSearchAnswers"
          />

          <datalist id="vlsKeywords">
            <option
              v-for="{ id, name } in vlsKeywords"
              :key="id"
              :value="name"
            />
          </datalist>

          <!-- Popular answers -->
          <Collapse
            :initiallyCollapsed="question.popularAnswersCollapsed"
            @toggle="
              isCollapsed => (question.popularAnswersCollapsed = isCollapsed)
            "
          >
            <template slot="label"
              >Top 10 gebruikte oplossingen</template
            >

            <span v-if="!decoratedPopularAnswers.length">Geen resultaten</span>

            <AnswersList
              :answers="decoratedPopularAnswers"
              @open-link="openAnswerInTab(arguments[0])"
              @open="openAnswerInIframe(arguments[0])"
              @use="useAnswer(arguments[0])"
            />
          </Collapse>

          <!-- Results displayed in iframe -->
          <div
            v-if="iframeUrl"
            ref="iframe"
            class="vlw__cti-question__answers__results"
          >
            <!-- Buttons on top of iframe -->
            <div class="vlw__cti-question__iframe-buttons">
              <!-- Open in new tab button -->
              <VlButton
                :mod-secondary="true"
                :mod-icon="true"
                icon="external"
                @click.prevent="openIframeInTab"
              />

              <!-- Close button -->
              <VlButton
                :mod-secondary="true"
                :mod-icon="true"
                icon="cross"
                @click.prevent="closeIframe"
              />
            </div>

            <!-- iFrame -->
            <iframe :src="iframeUrl"></iframe>
          </div>
        </div>

        <!-- Linked solutions -->
        <div class="vlw__cti-linked-solutions">
          <!-- Heading -->
          <h3>Gekoppelde oplossingen</h3>

          <!-- No solutions -->
          <div v-if="!question.answers.length">
            Geen gekoppelde oplossingen.
          </div>

          <!-- Table -->
          <VlDataTable v-if="question.answers.length" :mod-hover="true">
            <thead>
              <tr>
                <th>Titel</th>
                <th>Type</th>
                <th>Doorschakelen</th>
                <th>Doorsturen</th>
                <th>Geëscaleerd</th>
                <th>Oplossing ID</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="a in question.answers"
                :key="a.id"
                :class="{ 'vlw__cti-linked-solution--loading': a.loading }"
              >
                <td>
                  <VlLink href="#" @click.prevent="openAnswerInTab(a)">
                    {{ a.title }}
                  </VlLink>
                </td>
                <td>{{ a.category }}</td>
                <td>{{ a.telephoneTransferInfo }}</td>
                <td>{{ a.emailTransferInfo }}</td>
                <td>{{ a.isEscalated ? 'Ja' : '' }}</td>
                <td>{{ a.id }}</td>
                <td class="vl-u-align-right">
                  <VlLoader v-if="a.loading"></VlLoader>
                  <VlLink
                    v-if="!a.loading"
                    href="#"
                    @click.prevent="deleteAnswer(a)"
                    >Verwijder</VlLink
                  >
                </td>
              </tr>
            </tbody>
          </VlDataTable>
        </div>
      </div>
    </Collapse>

    <!-- Citizen Modal -->
    <portal to="modal" v-if="showCitizenModal">
      <Citizen
        :search="search"
        @close="showCitizenModal = false"
        @save="onSelectCitizen"
        @select="onSelectCitizen"
      ></Citizen>
    </portal>

    <portal to="modal" v-if="showSearchChildModal">
      <SearchChild
        :formData="search"
        @close="showSearchChildModal = false"
        @select="handleOnSelectChild"
      />
    </portal>

    <portal to="modal" v-if="showEscalationPointsModal">
      <EscalationPoints
        :question="question"
        :disabled="isDirty"
        :manuallyEscalateArticelId="manuallyEscalateArticelId"
        :isScreenSharingAllowed="this.isScreenSharingAllowed"
        @close="showEscalationPointsModal = false"
      ></EscalationPoints>
    </portal>
  </div>
</template>

<script>
// Import components
import { mapState, mapActions, mapGetters } from 'vuex';
import { debounce } from 'lodash';
import { propEq } from 'lodash/fp';
import VueScrollTo from 'vue-scrollto';
import qs from 'qs';

import { crmAxios } from '@/utils/Axios';

import CitizenClass from '@/modules/Citizen';
import {
  DEBOUNCE_DELAY,
  priorities,
  responseTypeCodes,
  themes,
  questionStatusIds,
  questionSubStatusIds,
  vlsUrl,
} from '@/config';
import languages from '@/config/languages';

import AnswersList from './AnswersList';
import Citizen from './Citizen';
import CitizenDetails from './CitizenDetails';
import EscalationPoints from './EscalationPoints';
import ExternalApplicationsButtons from './ExternalApplicationButtons';
import ManuallyEscalateButtons from './ManuallyEscalateButtons';
import MiragePersonDetails from './MiragePersonDetails';
import QuestionVariableFields from './QuestionVariableFields';
import SearchChild from './modals/SearchChild';
import Screenshots from './Screenshots';

// Options for auto scrolling to the iframe
const scrollToOptions = {
  container: 'body',
  easing: 'ease',
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true,
};

export default {
  name: 'Question',
  components: {
    AnswersList,
    Citizen,
    CitizenDetails,
    EscalationPoints,
    ExternalApplicationsButtons,
    ManuallyEscalateButtons,
    MiragePersonDetails,
    QuestionVariableFields,
    SearchChild,
    Screenshots,
  },
  props: {
    question: {
      type: Object,
      required: true,
    },
  },
  // Array with watch hooks, to be cleaned up when the component is destroyed
  watchers: [],
  data() {
    return {
      // Whether to show the citizen modal
      showCitizenModal: false,

      // Whether to show the escalation points modal
      showEscalationPointsModal: false,

      showSearchChildModal: false,

      // The array of response type codes
      responseTypeCodes,

      // URL to VLS for selected answer
      iframeUrl: null,

      // The value searched for in answers
      searchValue: '',

      // An citizen instance that provides initial search parameters
      search: new CitizenClass(),

      manuallyEscalateArticelId: null,

      isDirty: false,
    };
  },
  created() {
    // Setup a debounced watcher
    const debouncedQuestionChange = debounce(
      this.onQuestionChange,
      DEBOUNCE_DELAY,
    );

    // Setup the watcher for props that can change (no deep watcher as solutions have a different endpoint)
    const watchedQuestionProps = [
      'title',
      'extraInformation',
      'citizen.id',
      'subStatus',
      'themeId',
      'priorityCode',
      'responseTypeCode',
      'nationalRegistryNumber',
      'relationshipTypeCode',
      'licensePlate',
      'articleNumber',
      'subscriptionNumber',
      'kboNumber',
      'recognitionNumber',
      'bisNumber',
      'kindEnGezin_PersonId',
      'organization',
      'attestNumber',
      'daycare',
      'addScreenshotsForEscalationEmail',
    ];
    watchedQuestionProps.forEach(prop => {
      // Add a watcher
      this.$options.watchers.push(
        this.$watch(`question.${prop}`, (...args) => {
          if (this.isReadOnly) return; //Do not save changes when read-only (escalated question)

          this.$store.state.isDirty = true;
          this.isDirty = true;
          return debouncedQuestionChange(...args);
        }),
      );
    });

    // Setup a separate watcher for the theme, so popular answers can get updated when the theme changes
    this.$options.watchers.push(
      this.$watch('question.themeId', this.getPopularAnswers),
    );

    // IC-2352 voorlichten knop niet zichtbaar (dirty quick fix)
    this.$options.watchers.push(
      this.$watch('iframeUrl', newVal => {
        if (newVal) {
          this.$nextTick(() => {
            const iframeElement = this.$refs.iframe.querySelector('iframe');

            if (iframeElement == null) {
              console.error('Cannot find iframe element.');
            }

            iframeElement.dispatchEvent(new Event('resize'));
          });
        }
      }),
    );
  },

  async mounted() {
    // Retrieve the popular answers for this question
    this.getPopularAnswers();

    if (this.defaultEscalationPointId != null) {
      const {
        data: { articleId },
      } = await crmAxios({
        url: `/EscalationPoint/${this.defaultEscalationPointId}`,
      });

      this.manuallyEscalateArticelId = articleId;
    }
  },

  computed: {
    ...mapState('contact', ['contact', 'loading']),
    ...mapState('metadata', ['lines', 'contactStatuses', 'questionStatuses']),
    ...mapGetters('answers', ['getPopularAnswersByThemeId']),
    ...mapGetters('metadata', [
      'availableThemesByLineId',
      'getThemeById',
      'getStatusIdBySubStatusId',
    ]),
    ...mapGetters('contact', [
      'contactHasCitizenSelected',
      'defaultEscalationPointId',
      'outboundCalls',
      'requiredEndRegistrationFieldsByApiName',
      'showQuickSelectCitizen',
      'isScreenSharingAllowed',
    ]),

    theme() {
      return this.getThemeById(this.question.themeId);
    },

    vlsKeywords() {
      if (!this.theme) {
        return [];
      }

      return this.theme.searchTerms || [];
    },

    popularAnswers() {
      return this.getPopularAnswersByThemeId(this.theme.id);
    },

    // Popular answers to this question
    decoratedPopularAnswers() {
      return (this.popularAnswers || [])
        .filter(({ id }) => id !== this.manuallyEscalateArticelId)
        .map(a => {
          // Check if an answer is linked as a solution
          a.linked = !!this.question.answers.find(
            answer => answer.id.toString() === a.id.toString(),
          );

          // Generate a url to use
          const params = {
            article_id: a.id,
            line_code_id: this.theme.code,
            question_id: this.question.internalId,
            origin: window.location.origin,
          };
          a.url = `${vlsUrl}/?${qs.stringify(params)}`;

          // Return the answer
          return a;
        });
    },

    questionTitle() {
      const { internalId, questionNumber } = this.question;

      return `Vraag ${internalId}${
        questionNumber ? `: ${questionNumber}` : ''
      }`;
    },

    priorities() {
      return priorities;
    },

    languages() {
      return languages;
    },

    // Themes in selected line
    themes() {
      return this.availableThemesByLineId(this.contact.lineId);
    },

    statusId() {
      return this.getStatusIdBySubStatusId(
        'questionStatuses',
        this.question.subStatus,
      );
    },

    selectedStatus() {
      return this.getStatusById(this.statusId) || {};
    },

    // List of sub statuses for selected status
    subStatuses() {
      return this.selectedStatus.subStatuses || [];
    },

    // Computed to get the selected escalation point as an object
    selectedEscalationPoint() {
      // Exit early if not escalated
      if (!this.question.escalationPoint) {
        return false;
      }

      return this.question.escalationPoints.find(
        ep => ep.id === this.question.escalationPoint.id,
      );
    },

    hasAccess() {
      const themes = this.availableThemesByLineId(this.contact.lineId);
      return themes.filter(({ id }) => id === this.question.themeId).length > 0;
    },

    isReadOnly() {
      // user themes
      if (!this.hasAccess) {
        return true;
      }

      const { GEESCALEERD } = questionStatusIds;

      if (!this.statusId === GEESCALEERD) {
        return false;
      }

      const { ESCALATED_PHONE, ESCALATED_EMAIL } = questionSubStatusIds;
      const { escalationPoint, subStatus } = this.question;
      const { emailEscalationTransferType, phoneEscalationTransferType } =
        escalationPoint || {};
      const BACKOFFICE = 'Backoffice';

      return (
        (subStatus === ESCALATED_PHONE &&
          phoneEscalationTransferType === BACKOFFICE) ||
        (subStatus === ESCALATED_EMAIL &&
          emailEscalationTransferType === BACKOFFICE)
      );
    },

    // Computed to check if the `Behandeltheme` select is disabled
    isThemeDisabled() {
      // Disabled if read only, or if there are solutions linked
      return this.isReadOnly || this.question.answers.length > 0;
    },

    isKindEnGezin() {
      return this.question.themeId === themes.KIND_EN_GEZIN;
    },

    hasCitizenSelected() {
      return !!this.question.citizen.id;
    },

    hasChildSelected() {
      return !!this.question.kindEnGezin_PersonId;
    },

    canEscalate() {
      if (!this.question.title || this.question.escalationPoint) {
        return false;
      }

      if (this.isKindEnGezin) {
        const hasChildOrCitizen =
          this.hasCitizenSelected || this.hasChildSelected;

        return hasChildOrCitizen && this.contactHasCitizenSelected;
      }

      return true;
    },

    canManuallyEscalate() {
      return [themes.FIT, themes.VITO, themes.CC_ONDERNEMEN].includes(
        this.question.themeId,
      );
    },

    hasRegularEscalationPoints() {
      return (
        this.question.escalationPoints.filter(
          ({ id }) => id !== this.manuallyEscalateArticelId,
        ).length > 0
      );
    },

    isLanguageRequired() {
      return this.requiredEndRegistrationFieldsByApiName.includes(
        'completionInfo_Language',
      );
    },

    showLanguageIfNotRequiredInEndRegistration() {
      return !this.isLanguageRequired;
    },
  },

  methods: {
    ...mapActions('contact', ['saveQuestion', 'escalateQuestion']),
    ...mapActions('citizen', ['changeMode']),

    getStatusById(id) {
      return this.questionStatuses.find(propEq('id', id)) || {};
    },

    // Triggered when the theme of the question changes
    getPopularAnswers() {
      // Dispatch an action to update the given question with the popular answers for the given theme
      this.$store.dispatch('answers/getPopular', {
        theme: this.theme,
        question: this.question,
      });
    },

    // Method called by watcher if changes to question occur
    async onQuestionChange() {
      if (this.isReadOnly) {
        return;
      }

      await this.saveQuestion(this.question);
      this.$store.state.isDirty = false;
      this.isDirty = false;
    },

    // Helper to get classes for pills
    labelClass(label) {
      const obj = {};
      obj['vl-pill'] = true;
      if (label.type) {
        obj[`vl-pill--${label.type}`] = true;
      }
      return obj;
    },

    // Method to be called by parent to focus on question input
    focus() {
      if (this.$refs && this.$refs.title && this.$refs.title.focus) {
        this.$refs.title.focus();
      }
    },

    isStatusDisabled({ id, subStatuses }) {
      if (id === questionStatusIds.REGIO_ACTIE) {
        if (
          !this.contactHasCitizenSelected ||
          (!this.hasCitizenSelected && !this.hasChildSelected)
        ) {
          return true;
        }
      }

      return !subStatuses.reduce(
        (acc, { manuallySelectable }) => acc || manuallySelectable,
        false,
      );
    },

    isSubStatusDisabled({ id, manuallySelectable }) {
      if (!manuallySelectable) {
        return true;
      }

      if (this.statusId === questionStatusIds.REGIO_ACTIE) {
        return id !== questionSubStatusIds.REGIO_ACTIE_DIRECT_ANTWOORD;
      }

      return false;
    },

    // Method triggered when the status of the question changes
    handleOnChangeStatus({ target }) {
      const { value } = target;

      if (value === questionStatusIds.REGIO_ACTIE) {
        this.question.subStatus =
          questionSubStatusIds.REGIO_ACTIE_DIRECT_ANTWOORD;
        return;
      }

      const { subStatuses = [] } = this.getStatusById(value) || {};

      const { id } = subStatuses.find(propEq('manuallySelectable', true)) || {};

      this.question.subStatus = id;
    },

    handleOnSelectChild(miragePersoonId) {
      this.unlinkCitizen();

      this.question.kindEnGezin_PersonId = miragePersoonId;
    },

    // Method to delete an answer from a question
    deleteAnswer(answer) {
      this.$store.dispatch('contact/deleteAnswer', {
        question: this.question,
        answer,
      });
    },

    // Method triggered when a citizen is selected
    onSelectCitizen(citizenInstance) {
      // Update the citizen
      this.question.citizen = citizenInstance;

      this.unlinkChild();
    },

    // Method triggered when unlinking a citizen
    unlinkCitizen() {
      this.question.citizen = new CitizenClass();
    },

    unlinkChild() {
      this.question.kindEnGezin_PersonId = null;
    },

    // Open iframe in new tab
    openIframeInTab() {
      window.open(this.iframeUrl);
    },

    // Close the iframe
    closeIframe() {
      // Set the iframe URL to an empty URL
      this.iframeUrl = '';
    },

    // Open answer in new tab
    openAnswerInTab(answer) {
      // Build the URL
      let url;

      // Check if the answer has a `url` property (only the case if data comes from VLS)
      if (answer.url) {
        url = answer.url;
      } else {
        // Set the params to search
        const params = {
          line_code_id: this.theme.code,
          article_id: answer.id,
          origin: window.location.origin,
        };

        // Update the iframe URL
        url = `${vlsUrl}/?${qs.stringify(params)}`;
      }

      // Open the URL is a new tab
      window.open(url);
    },

    // Select answer to display in iframe
    openAnswerInIframe(answer) {
      // Collapse the top 10
      this.question.popularAnswersCollapsed = true;

      if (!this.question.internalId) {
        throw new Error(
          'Cannot open question in iFrame; internalId is missing.',
        );
      }

      // Update the iframe url
      this.iframeUrl = `${answer.url}`;

      // Scroll to the frame
      this.$nextTick(() => {
        if (this.$refs.iframe) {
          VueScrollTo.scrollTo(this.$refs.iframe, 500, scrollToOptions);
        }
      });
    },

    // Use answer (voorlichten)
    useAnswer(a) {
      // Get the data from the VLS
      this.$store
        .dispatch('answers/retrieveAnswersFromVLS', { articleId: a.id })
        .then(answers => {
          answers.forEach(answer => {
            this.$store.dispatch('contact/useAnswer', {
              question: this.question,
              answer,
            });
          });
        });
    },

    // Triggered when searching for answers
    onSearchAnswers() {
      // Collapse the popular answers
      this.question.popularAnswersCollapsed = true;

      // Set the params to search
      const params = {
        keyword: this.searchValue,
        line_code_id: this.theme.code || '1700-andere-vragen',
        question_id: this.question.internalId,
        origin: window.location.origin,
      };

      // Update the iframe URL
      this.iframeUrl = `${vlsUrl}/?${qs.stringify(params)}`;
    },

    // Triggered when searching for a citizen
    onSearchCitizen() {
      if (this.showQuickSelectCitizen) {
        this.$store.dispatch('citizen/getQuickSelectCitizens').then(() => {
          this.changeModeToSearch();
        });
      } else {
        this.changeModeToSearch();
      }
    },

    changeModeToSearch() {
      this.changeMode('search');

      // Show the modal
      this.showCitizenModal = true;
    },

    // Triggered when clicked on the citizen's name
    onOpenCitizen() {
      // Edit the selected citizen
      this.$store.dispatch('citizen/edit', this.question.citizen.id);

      // Show the modal
      this.showCitizenModal = true;
    },
  },
};
</script>

<style lang="scss">
// Wrapper
.vlw__cti-question {
  margin-bottom: 20px;

  h2 {
    color: #0055cc;
    font-size: 18px;
    font-weight: 500;
  }

  h3 {
    margin-bottom: 15px;
  }

  h4 {
    font-size: 18px;
    font-weight: 500;
  }

  .vlw__collapse__inner-container {
    padding: 0 15px 2rem 15px;
  }
}

.vlw__cti-question--loading {
  position: relative;
  opacity: 0.5;

  * {
    pointer-events: none;
  }
}

// Title wrapper
.vlw__cti-question__title {
  display: flex;
  margin-bottom: 20px;
  align-items: stretch;
}

// Toggle expanded
.vlw__cti-question__toggle {
  position: relative;
  padding-left: 25px;
  flex: 1 0 auto;
  text-decoration: none;
  display: flex;
  align-items: center;

  &:hover {
    h2 {
      text-decoration: underline;
    }
  }

  > .vl-icon {
    color: #000;
    font-size: 12px;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    transition: transform ease-out 0.15s;
  }

  &.expanded {
    > .vl-icon {
      transform: translateY(-50%) rotate(90deg);
    }
  }
}

// Statuses
.vlw__cti-question__status {
  margin-left: 20px;

  > span {
    margin-right: 5px;

    &:last-child {
      margin: 0;
    }
  }
}

// Delete
.vlw__cti-question__delete {
  align-self: center;
  flex: 0 0 auto;
  text-align: right;
}

// Details
.vlw__cti-question__details {
  display: flex;
  margin-bottom: 20px;
}

.vlw__cti-question__details__item {
  margin-bottom: 20px;
}

// Detail label
.vlw__cti-question__details__item--label {
  color: #666;
  font-weight: 500;
}

// Content
.vlw__cti-question__content {
  margin-bottom: 20px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  grid-gap: 20px 0;

  > div {
    flex: 1 0 50%;

    &:first-child {
      padding-right: 20px;
    }
    &:nth-child(2) {
      padding-right: 20px;
    }
  }

  label {
    display: flex;
    align-items: center;
    min-height: 35px;
    margin-bottom: 10px;

    > span {
      flex: 0 0 150px;

      &.value {
        font-weight: normal;
      }
    }

    > input {
      flex: 1 1 auto;
    }
  }

  .vl-textarea {
    min-height: 88px;
    resize: vertical;
  }
}

// Content title
.vlw__cti-question__content--title {
  color: #666;
  font-weight: 500;
}

// Citizen wrapper
.vlw__cti-question__content__citizen {
  margin-bottom: 20px;
}

// Solutions
.vlw__cti-question__solutions {
  margin-bottom: 20px;
}

// Solutions title
.vlw__cti-question__solutions--title {
  color: #666;
  font-weight: 500;
}

// Solution
.vlw__cti-question__solutions__item {
  border: 1px solid #cbd2d9;
  padding: 20px;
}

// Solution details
.vlw__cti-question__solutions__item__details {
  display: flex;
}

// Solution detail
.vlw__cti-question__solutions__item__details__item {
  margin-left: 60px;

  &:first-child {
    margin: 0;
  }
}

// Solution detail title
.vlw__cti-question__solutions__item__details__title {
  font-weight: 500;
  margin-bottom: 10px;
}

// Solution detail value
.vlw__cti-question__solutions__item__details__item__value {
  font-weight: 500;
}

// Solution
.vlw__cti-linked-solution--loading {
  opacity: 0.5;
  pointer-events: none;
}

// Answers
.vlw__cti-question__answers {
  margin-bottom: 20px;
}

// Results
.vlw__cti-question__answers__results {
  height: calc(100vh - 200px);
  background: #f3f3f3;
  border: 1px solid #ddd;
  position: relative;
  margin: 0 15px;
  overflow: auto;
  overscroll-behavior: contain;

  > iframe {
    width: 100%;
    height: calc(100% - 10px);
    z-index: 0;
  }
}

// Buttons on top of iframe
.vlw__cti-question__iframe-buttons {
  display: flex;
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 1;

  > button {
    padding: 1px 10px;
    background: #fff;
    margin-left: 5px;

    &:hover,
    &:focus,
    &:active {
      background: #fff;
    }
  }
}

// Answers title
.vlw__cti-question__answers__title {
  color: #666;
  font-weight: 500;
}
</style>
