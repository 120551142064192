<template>
  <!-- Form wrapper -->
  <form
    :action="uri"
    method="post"
    target="_blank"
    @submit.prevent="handleOnSubmit"
  >
    <VlAlert v-if="error" :mod-error="true" :content="error.message" />

    <div v-else>
      <!-- Link -->
      <VlLink
        v-if="isLink"
        target="_blank"
        :href="linkUrl"
        icon="external"
        :mod-icon-after="true"
        class="vl-button vl-button--narrow vl-button--secondary"
        >{{ name }}</VlLink
      >

      <!-- Button -->
      <VlButton
        v-if="!isLink"
        icon="external"
        :mod-narrow="true"
        :mod-secondary="true"
        :mod-icon-after="true"
        :mod-loading="isLoading"
        >{{ name }}</VlButton
      >

      <!-- Hidden form inputs to submit -->
      <input
        v-for="hi in hiddenInputs"
        :key="hi.name"
        type="hidden"
        :name="hi.name"
        :value="hi.value"
      />
    </div>
  </form>
</template>

<script>
// Import modules
import { mapState } from 'vuex';
import { entries, toLower } from 'lodash';
import { crmAxios } from '@/utils/Axios';
import { stripNonNumerics } from '@/utils';

const [ECAD, KINDENGEZIN002, STUDIETOELAGEN, ZOFA, ZORBA] = [
  'ECAD',
  'KINDENGEZIN002',
  'STUDIETOELAGEN',
  'ZOFA',
  'ZORBA',
];

// Export component
export default {
  props: {
    question: {
      type: Object,
      required: true,
    },

    uri: {
      type: String,
      required: true,
    },

    technicalName: {
      type: String,
      required: true,
    },

    name: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      error: null,
      isLoading: false,

      // Array of objects with `name` and `value` to use for rendering hidden inputs in the form
      hiddenInputs: [],
    };
  },

  computed: {
    ...mapState('mirage', ['miragePersonsById']),

    // Computed to get the national registry number (numbers only)
    nationalNumberOnlyNumbers() {
      return stripNonNumerics(this.question.nationalRegistryNumber || '');
    },

    // Computed to check if the button is a simple link
    isLink() {
      // Always true if technical name is unknown
      if (
        ![ECAD, KINDENGEZIN002, STUDIETOELAGEN, ZOFA, ZORBA].includes(
          this.technicalName,
        )
      ) {
        return true;
      }

      // Exception for ECAD if no RRN is given
      if (this.technicalName === ECAD && !this.nationalNumberOnlyNumbers) {
        return true;
      }

      // Default, only for K&G
      return [KINDENGEZIN002].includes(this.technicalName);
    },

    // Computed to return the link url
    linkUrl() {
      if (this.technicalName !== KINDENGEZIN002) {
        return this.uri;
      }

      const { mirageUrl } =
        this.miragePersonsById[this.question.kindEnGezin_PersonId] || {};

      return mirageUrl || this.uri;
    },
  },

  methods: {
    // Triggered when form is submitted
    async handleOnSubmit() {
      // Start loading
      this.isLoading = true;

      // Clear previous errors
      this.error = null;

      // Empty hiddenInputs array
      this.hiddenInputs = [];

      // Retrieve parameters for opening external application
      try {
        const {
          data: { body },
        } =
          this.technicalName === STUDIETOELAGEN
            ? { data: { body: {} } }
            : await crmAxios({
                url: `/ExternalApplication/${toLower(
                  this.technicalName,
                )}?nationalNumber=${this.nationalNumberOnlyNumbers}`,
                method: 'post',
              });

        // Update the hiddenInputs array
        entries(body).forEach(([name, value]) =>
          this.hiddenInputs.push({ name, value }),
        );

        // Wait until the next DOM update cycle has finished (hidden inputs are rendered)
        await this.$nextTick();

        // Submit the form (root DOM element of this Vue instance)
        this.$el.submit();
      } catch (error) {
        // Remember error
        if (!error) {
          this.error = error.message || error.toString();
        }
      }

      // Stop loading
      this.isLoading = false;
    },
  },
};
</script>
