<template>
  <div>
    <!-- RRN number-->
    <label
      v-if="extraFieldsByApiName.includes('nationalRegistryNumber')"
      class="vl-form__label"
    >
      <span>{{
        labelByApiName('nationalRegistryNumber') || 'Rijksregisternr'
      }}</span>

      <masked-input
        type="text"
        class="vl-input-field"
        v-model="question.nationalRegistryNumber"
        placeholder="11.22.33-444.55"
        :class="{
          'vl-input-field--error': $v.question.nationalRegistryNumber.$error,
        }"
        @blur="$v.question.nationalRegistryNumber.$touch"
        :disabled="disabled"
        :mask="rrnMask"
        :guide="false"
      ></masked-input>
    </label>

    <label
      v-if="extraFieldsByApiName.includes('attestNumber')"
      class="vl-form__label"
    >
      <span>{{ labelByApiName('attestNumber') || 'Attestnummer' }}</span>

      <VlInputField
        maxlength="15"
        v-model="question.attestNumber"
        :disabled="disabled"
      />
    </label>

    <!-- KBO number-->
    <label
      v-if="extraFieldsByApiName.includes('kboNumber')"
      class="vl-form__label"
    >
      <span>{{ labelByApiName('kboNumber') || 'KBO nummer' }}</span>

      <VlInputField v-model="question.kboNumber" :disabled="disabled" />
    </label>

    <!-- BIS number-->
    <label
      v-if="extraFieldsByApiName.includes('bisNumber')"
      class="vl-form__label"
    >
      <span>{{ labelByApiName('bisNumber') || 'BIS nummer' }}</span>
      <VlInputField v-model="question.bisNumber" :disabled="disabled" />
    </label>

    <!-- Recognition number (erkenningsnummer VAPH) -->
    <label
      v-if="extraFieldsByApiName.includes('recognitionNumber')"
      class="vl-form__label"
    >
      <span>{{
        labelByApiName('recognitionNumber') || 'Erkenningsnummer'
      }}</span>
      <VlInputField v-model="question.recognitionNumber" :disabled="disabled" />
    </label>

    <!-- Kohierartikelnummer -->
    <label
      v-if="extraFieldsByApiName.includes('articleNumber')"
      class="vl-form__label"
    >
      <span>{{
        labelByApiName('articleNumber') || 'Kohierartikelnummer'
      }}</span>
      <VlInputField v-model="question.articleNumber" :disabled="disabled" />
    </label>

    <!-- License plate -->
    <label
      v-if="extraFieldsByApiName.includes('licensePlate')"
      class="vl-form__label"
    >
      <span>{{ labelByApiName('licensePlate') || 'Nummerplaat' }}</span>
      <VlInputField v-model="question.licensePlate" :disabled="disabled" />
    </label>

    <!-- Abonnementsnummer De Lijn -->
    <label
      v-if="extraFieldsByApiName.includes('subscriptionNumber')"
      class="vl-form__label"
    >
      <span>{{ labelByApiName('subscriptionNumber') || 'Abonnementsnr' }}</span>
      <VlInputField
        v-model="question.subscriptionNumber"
        :disabled="disabled"
      />
    </label>

    <!-- Organisatie -->
    <label
      v-if="extraFieldsByApiName.includes('organization')"
      class="vl-form__label"
    >
      <span>{{ labelByApiName('organization') || 'Organisatie' }} </span>
      <VlInputField v-model="question.organization" :disabled="disabled" />
    </label>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { prop, propEq } from 'lodash/fp';
import MaskedInput from 'vue-text-mask';
import validateNationRegistryNumber from '@/utils/validate-national-registry-number';

// Export component
export default {
  components: {
    MaskedInput,
  },

  data() {
    return {
      // https://github.com/text-mask/text-mask/issues/836#issuecomment-433691352
      rrnMask: [
        /[0-9]/,
        /[0-9]/,
        '.',
        /[0-9]/,
        /[0-9]/,
        '.',
        /[0-9]/,
        /[0-9]/,
        '-',
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        '.',
        /[0-9]/,
        /[0-9]/,
      ],
    };
  },

  props: {
    question: {
      type: Object,
      required: true,
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  validations: {
    question: {
      nationalRegistryNumber: { validateNationRegistryNumber },
    },
  },

  computed: {
    ...mapGetters('metadata', ['getThemeById']),

    currentTheme() {
      return this.getThemeById(this.question.themeId);
    },

    extraFields() {
      return this.currentTheme ? this.currentTheme.extraFields || [] : [];
    },

    extraFieldsByApiName() {
      return this.extraFields.map(prop('apiName'));
    },
  },

  methods: {
    labelByApiName(apiName) {
      const obj = this.extraFields.find(propEq('apiName', apiName));

      return obj ? obj.name : undefined;
    },
  },
};
</script>
